import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

window.api = 'http://127.0.0.1:3000'
if(window.location.href.includes('.com')){
  window.api = 'https://serverbioraw.brmja.com'
}

Vue.config.productionTip = false

if(window.location.href.includes("lang")){
  localStorage.setItem("lang", window.location.href.split("lang=")[1])
  window.location = window.location.href.replace("lang=","f=");
}

import lang_array from './languages.json';

if(!localStorage.getItem("lang")){
  localStorage.setItem("lang", "ar")
}

var langname = localStorage.getItem('lang')  ?? 'ar';

window.lang = (word) => {
  if (lang_array[word]) {
    return lang_array[word][langname];
  } else {
    return word;
  }
};

Vue.prototype.lang = lang;

// check auth
if(!localStorage.getItem('user')){
  if(!window.location.href.includes("login") && !window.location.href.includes("register") && !window.location.href.includes("activate")){
    window.location = '/login'
  }
}
setInterval(function(){
  if(!localStorage.getItem('user')){
    if(!window.location.href.includes("login") && !window.location.href.includes("register") && !window.location.href.includes("activate")){
      window.location = '/login'
    }
  }
}, 100)
// end check auth

window.alert = function(text, status='bg-secondary'){
  $("#open-alert").click()
  setTimeout(function(){
    $("#alert-msg").html(`<div class='alert text-white g text-center ${status == 100 ? 'bg-success' : (status == 200 ? 'bg-danger' : 'bg-secondary')}'>${text}</div>`)
  }, 100)
}

if(localStorage.getItem('lang')){
  $("html").attr('lang', localStorage.getItem('lang'))
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
